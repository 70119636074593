import React, { useContext, useState, useEffect } from "react"
import { StoreContext } from "../../contexts/Store"
import Layout from "../../Layout"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"
import currencyFormat from "../../utils/currencyFormat"
import Loading from "../../components/Loading"
import * as s from "./index.module.scss"

const PriceListPage = () => {
  const [searchString, setSearchString] = useState("")
  const [list, setList] = useState([])
  const {
    store: { products },
  } = useContext(StoreContext)

  useEffect(() => {
    window.location.href = "https://store.owita.lk/price-list"
    if (products && products.length > 0) {
      setList(products)
    }
  }, [products])

  const search = e => {
    const value = e.target.value
    setSearchString(value)

    if (value.length > 2 && products && products.length > 0) {
      // search products
      setList(
        products.filter(
          ({ name, other_names }) =>
            (name && name.toLowerCase().indexOf(value.toLowerCase()) > -1) ||
            (other_names &&
              other_names.toLowerCase().indexOf(value.toLowerCase()) > -1)
        )
      )
    } else {
      setList(products)
    }
  }

  return (
    <Layout>
      <Seo title="Price List" />
      <Breadcrumbs title="Price List" />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <p className="text-center mt-5">
              Products are listed in alphabetical order.
            </p>
            <input
              value={searchString}
              onChange={search}
              type={"text"}
              className="form-control"
              placeholder={"Type here to search"}
            />
            {list.length === 0 ? (
              <div className="text-center mt-5">
                <Loading />
              </div>
            ) : (
              <ProductList products={list} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PriceListPage

const ProductList = ({ products }) => {
  return (
    <table className="table table-sm table-hover table-striped mt-5">
      <thead>
        <tr>
          <th className={"px-0 py-0"} />
          <th>Product</th>
          <th className={"text-center"} colSpan={"3"}>
            Price
          </th>
        </tr>
      </thead>
      <tbody>
        {products
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          .map(product => {
            let unit = `${product.pos_unit.measure} ${product.pos_unit.label}`
            if (Number(product.pos_unit.measure_after_label)) {
              unit = `${product.pos_unit.label} ${product.pos_unit.measure}`
            }
            return (
              <tr key={product.id}>
                <td
                  style={{ width: "40px", maxWidth: "40px" }}
                  className={"px-0 py-0"}
                >
                  <img
                    src={product.image}
                    alt={product.name}
                    height={35}
                    className={s.image}
                  />
                </td>
                <td className={"d-flex align-items-center"}>{product.name}</td>
                <td className={"text-right"}>
                  {currencyFormat(product.pos_price)}
                </td>
                <td className={"text-center"}>/</td>
                <td className={"text-left text-nowrap"}>{unit}</td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}
